import React, {  } from 'react'
import {
	Routes,
	Route,
	useLocation} from "react-router-dom"

import { Home } from './paginas/Externo/home/home'
import { Cadastro } from './paginas/Externo/cadastro/cadastro'
import { CadastroPos } from './paginas/App/cadastroPos/cadastroPos'
import { Login } from './paginas/Externo/login/login'
import { RecuperarSenha } from './paginas/Externo/recuperarSenha/recuperarSenha'

import TestarStatus from './paginas/App/testarStatus/TestarStatus'
import IndicarCliente from './paginas/App/indicarCliente/IndicarCliente'
import Relatorios from './paginas/App/relatorios/Relatorios'
import Configuracoes from './paginas/App/configuracoes/Configuracoes'

import { AnimatePresence } from 'framer-motion'

// ROTAS DO APP
import Mapa from './paginas/App/mapa/mapa'

import NovaIndicacao from './paginas/App/indicarCliente/NovaIndicacao'
import RelatoriosIndicacoes from './paginas/App/indicarCliente/Relatorio'
import Servicos from './paginas/App/servicos/Servicos'
import ServicoDetalhes from './paginas/App/servicos/ServicoDetalhes.jsx'
import Cotacoes from './paginas/App/cotacoes/Cotacoes'
import PuxarServico from './paginas/App/puxarServico/puxarServico'
import ServicoFinalizar from './paginas/App/servicos/ServicoFinalizar'
import Mensagens from './paginas/App/mensagens/mensagens'
import ServicoAssinar from './paginas/App/servicos/ServicoAssinar'
import Playground from './paginas/Outros/Playground'
import Perfil from './paginas/App/perfil/perfil'
import MeusDados from './paginas/App/perfil/perfilMeusDados'
import PoliticaPrivacidade from './paginas/App/configuracoes/PoliticaPrivacidade'
import TermosUso from './paginas/App/configuracoes/TermosUso'
import MinhaAvaliacao from './paginas/App/perfil/MinhaAvaliação'
import DadosUsuario from './paginas/App/dadosUsuario/DadosUsuario'
import Inicial from './paginas/Externo/inicial/Inicial'
import LGPD from './paginas/App/configuracoes/LGPD'
import IntegracaoAbbiamo from './paginas/App/Integracoes/Abbiamo'
import EnderecosOrdenados from './paginas/App/Integracoes/Abbiamo/EnderecosOrdenados'
import Relatar from './Relatar'
import AppLayout from './AppLayout'
import Chat from "./paginas/App/chat/Main"

export function Rotas() {
	const location = useLocation()

	return <AnimatePresence mode='wait'>
		<Routes key={location.pathname} location={location}>
			<Route index element={<Inicial />} />
			<Route path='/home' element={<Home />} />
			<Route path='/cadastro' element={<Cadastro />} />
			<Route path='/login' element={<Login />} />
			<Route path='/recuperarSenha' element={<RecuperarSenha />} />

			<Route path='/app'>
				<Route path='/app/home' element={<Mapa />} />
				<Route path='/app/cadastro/pos' element={<CadastroPos />} />

				<Route path='/app/testarStatus' element={<TestarStatus />} />
				<Route path='/app/indicarCliente' element={<IndicarCliente />} />
				<Route path='/app/indicarCliente/novaIndicacao' element={<NovaIndicacao />} />
				<Route path='/app/indicarCliente/relatorio' element={<RelatoriosIndicacoes />} />
				<Route path='/app/relatorios' element={<Relatorios />} />

				<Route path='/app/configuracoes' element={<Configuracoes />} />
				<Route path='/app/politicaPrivacidade' element={<PoliticaPrivacidade />} />
				<Route path='/app/termosUso' element={<TermosUso />} />
				<Route path='/app/lgpd' element={<LGPD />} />

				<Route path='/app/mensagens' element={<Mensagens />} />
				<Route path='/app/chat' element={<Chat />} />

				<Route path='/app/servicos' element={<Servicos />} />
				<Route path='/app/servicos/:id' element={<ServicoDetalhes />} />
				<Route path='/app/servicos/:id/assinar' element={<ServicoAssinar />} />
				<Route path='/app/servicos/:id/finalizar' element={<ServicoFinalizar />} />

				<Route path='/app/cotacoes' element={<Cotacoes />} />
				<Route path='/app/puxarServico' element={<PuxarServico />} />

				<Route path='/app/dadosUsuario' element={<DadosUsuario />} />

				<Route path='/app/perfil' element={<Perfil />} />
				<Route path='/app/perfil/meusDados' element={<MeusDados />} />
				<Route path='/app/perfil/avaliacao' element={<MinhaAvaliacao />} />

				<Route path='/app/abbiamo' element={<IntegracaoAbbiamo />} />
				<Route path='/app/abbiamo/enderecosOrdenados' element={<EnderecosOrdenados />} />
			</Route>

			<Route path='/playground' element={<Playground />} />

			<Route path='/relatar' element={<Relatar />} />
		</Routes>
	</AnimatePresence>
}
