import { useContext, useEffect, useState, useReducer } from "react"
import { ContextoGeral } from "../../contextos/ContextoGeral"
import io from "socket.io-client"

export default function useChat() {
	const { dados, setDados } = useContext(ContextoGeral)

	const standard = null

	const [status, setStatus] = useState(0)
	const [socket, setSocket] = useState(null)
	const [chat, dispatch] = useReducer((state, action) => {
		if (action.type == "prevServico") {
			const n = state
			if (n?.salas && n.salas.servicos[action.sala]) {
				n.salas.servicos[action.sala].mensagens = [...n.salas.servicos[action.sala]?.mensagens, ...action.msgs]
				n.salas.servicos[action.sala].previousID = action.previousID
			}
			return n
		} else if (action.type == "prevCentral") {
			const n = state
			n.salas.central.mensagens = [...n.salas.central.mensagens, ...action.msgs]
			n.salas.central.previousID = action.previousID
			return n
		} else if (action.type == "addMsg")
			if (action.subType == "central")
				return {
					...state,
					salas: {
						...state.salas,
						central: {
							...state.salas.central,
							mensagens: [action.obj, ...state.salas.central.mensagens],
							unread: (state.salas.central?.unread ?? 0) + 1,
							previousID: state.salas.central.mensagens.length == 0
								? +action.obj.id
								: state.salas.central?.previousID
						}
					}
				}
			else {

				return {
					...state,
					salas: {
						...state.salas,
						servicos: {
							...state.salas.servicos,
							[action.subType]: {
								...state.salas.servicos[action.subType],
								mensagens: [action.obj, ...state.salas.servicos[action.subType].mensagens],
								unread: (state.salas.servicos[action.subType]?.unread ?? 0) + 1,
								previousID: state.salas.servicos[action.subType]?.mensagens.length == 0
									? +action.obj?.id
									: state.salas.servicos[action.subType]?.previousID
							}
						}
					}
				}
			}
		if (action.type == "connectCentral")
			return {
				...state,
				salas: {
					...state?.salas,
					central: {
						nome: `${dados.idConfig}_P${dados.idProf}`,
						mensagens: [],
						idSala: action.idSala,
						unread: action.unread
					}
				}
			}
		if (action.type == "connectRoom") return {
			...state,
			salas: {
				...state?.salas,
				servicos: {
					...state?.salas?.servicos,
					[action.roomName]: {
						nome: action?.roomName,
						mensagens: state?.salas?.servicos?.[action?.roomName]?.mensagens ?? [],
						idSala: action.idSala,
						unread: action.unread
					}
				}
			}
		}
		if (action.type == "clearCentral") {
			socket.emit('clearCountRoom', {
				roomName: `${dados.idConfig}_P${dados.idProf}`
			})

			return {
				...state,
				salas: {
					...state?.salas,
					central: {
						...state?.salas?.central,
						unread: 0
					}
				}
			}
		}
		if (action.type == "clearService") {
			socket.emit('clearCountRoom', {
				roomName: action.subType
			})

			return {
				...state,
				salas: {
					...state?.salas,
					servicos: {
						...state?.salas?.servicos,
						[action.subType]: {
							...state?.salas?.servicos[action.subType],
							unread: 0
						}
					}
				}
			}
		}
		if (action.type == "clearRooms") {
			const services = state?.salas?.servicos
			console.log(action.arrIdServico)
			if (services)
				Object.keys(services).map(key => {
					if (!action.arrIdServico.includes(key))
						services[key].unread = 0
				})

			return {
				...state,
				salas: {
					...state?.salas,
					servicos: services
				}
			}
		}
		if (action.type == "clear")
			return standard
		throw Error("Unknow Action")
	}, standard)

	useEffect(() => {
		if (!dados.idProf) return
		if (!dados?.chatConfig) return
		if (dados.chatConfig.status != "S") return

		let url = "https://chatmensagem.appmapp.com.br/"
		if (!dados.dominio) return
		else if (dados.dominio == "mototaxionline.com.br")
			url = "https://chatmensagem.mototaxionline.srv.br"

		const newSocket = io(url, {
			query: {
				idProf: dados.idProf,
				dominioAbreviado: dados?.dominio?.split(".")[0],
				dominioCompleto: dados.dominio,
				idConfig: dados.idConfig,
				nomeProf: dados.nome,
				identificador: "profissional"
			},
			transports: ["websocket", "polling"],
			reconnection: true,
			reconnectionDelay: 30000,
			reconnectionDelayMax: 30000,
			randomizationFactor: 0.5,
			timeout: 30000,
		})

		setSocket(newSocket)

		newSocket.on("connect", () => {
			console.log("Socket Connected")
		})

		return () => {
			console.log("cleaning up socket")
			newSocket.disconnect()
		}
	}, [dados.idProf, dados.idConfig, dados.dominio, dados.chatConfig])

	useEffect(() => {
		if (!socket) return
		socket.on("joinRoom", e => {
			console.log("Se conectou à sala " + e.roomName)
			console.log(e)
			if (e.roomName === `${dados.idConfig}_P${dados.idProf}`)
				dispatch({ type: "connectCentral", idSala: e.idSala, unread: e.msgNaoLida.profissional })
			else
				dispatch({ type: "connectRoom", idSala: e.idSala, roomName: e.roomName, unread: e.msgNaoLida.profissional })
		})

		socket.on("messageFromRoom", e => {
			console.log("recebeu mensagem")
			console.log(e)
			const messageData = {
				id: +e.idMensagem,
				txt: e.message,
				name: e.sender.identificador === "central"
					? "Central"
					: e.sender.nomeFunc ?? e.sender.nomeCliente ?? e.sender.nomeProf,
				outro: typeof e.sender.idProf == "undefined",
				enviado: true,
				recebido: true,
				date: new Date()
			}
			const roomName = e.roomName === `${dados.idConfig}_P${dados.idProf}` ? "central" : e.roomName
			dispatch({ type: "addMsg", subType: roomName, obj: messageData })
		})

		return () => {
			socket.off("joinRoom")
			socket.off("messageFromRoom")
		}
	}, [socket])

	const connectCentral = () => {
		if (socket) socket.emit("joinRoom", {
			roomName: `${dados.idConfig}_P${dados.idProf}`
		})
		console.log("enviou o evento de conexão")
	}

	const changeStatus = (newStatus) => setStatus(newStatus)

	const connectServico = (idServico, idCliente) => {
		if (socket) socket.emit("joinRoom", {
			roomName: `${dados.idConfig}_S${idServico}_${dados.idConfig}_POS${dados.idProf}_${dados.idConfig}_COS${idCliente}`,
			idServico: idServico,
			idProf: dados.idProf
		})
	}

	return { socket, chat, dispatch, connectCentral, connectServico, status, changeStatus }
}