import ProfissionalAPI from "./../../../servicos/ProfissionalAPI";
import MensagemAPI from "./../../../servicos/MensagemAPI";
import ServicoAPI from "./../../../servicos/ServicoAPI";
import AppAPI from "./../../../servicos/AppAPI";

import { useRef, useContext, useState, useEffect } from "react";
import { motion } from "framer-motion";

import { DivMapa } from "../../../componentes/Mapa";
import { BlocoFlutuante } from "../../../componentes/uteis/blocoFlutuante";
import { BtnRedondo } from "../../../componentes/btnRedondo";
import { BtnConectar } from "../../../componentes/BtnConectar";
import { AtivoPill } from "../../../componentes/usuario/AtivoPill";

import CheckPermission from "../../../servicos/config/CheckPermission.js";

import { MdMyLocation, MdPlaylistAddCheck, MdAddTask, MdAddLocationAlt, MdChat, MdMenu, MdNotifications } from "react-icons/md";

import { ContextoGeral } from "../../../contextos/ContextoGeral";

import "./mapa.module.css";
import MenuLateral from "../../../componentes/gerais/Menu";
import { useLocation, json, useNavigate } from "react-router-dom";
import { configAnim } from "../../../servicos/config/configAnim";
import { Spinner } from "../../../componentes/Spinner";
import { Avatar, Backdrop, Badge, Button, CircularProgress, Dialog, DialogActions, DialogContentText, DialogTitle, IconButton, Popover } from "@mui/material";
import MensagemApp from "../../../servicos/config/MensagemApp";
import { useLayoutEffect } from "react";
import { Balloon } from "./styles";
import useTestChecker from "../../../hooks/general/useTestChecker";
import { Close } from "@mui/icons-material";
import ChatIcon from '@mui/icons-material/Chat';
import useMessageCount from './../../../componentes/gerais/hook/useContarMensagens.jsx'

import APISemNode from "./../../../servicos/APISemNode";
import { ChatContext } from "../../../contextos/ContextoChat.jsx";

export default function Mapa() {
	const navegar = useNavigate();
	const { status } = useContext(ChatContext)

	const refPerfil = useRef();
	const refLocal = useRef();
	const refMenu = useRef();
	const refServices = useRef();
	const refCotation = useRef();
	const refPull = useRef();
	const refMsg = useRef();

	const [open, setOpen] = useState(false);
	const [tutorial, setTutorial] = useState(false);
	const [popAnchor, setPopAnchor] = useState(null);
	const [localAnchor, setLocalAnchor] = useState(null);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [servicesAnchor, setServicesAnchor] = useState(null);
	const [cotationAnchor, setCotationAnchor] = useState(null);
	const [pullAnchor, setPullAnchor] = useState(null);
	const [msgAnchor, setMsgAnchor] = useState(null);
	const [QTD, setQTD] = useState({});

	const [dialog, setDialog] = useState({
		show: false,
		title: "",
		text: "",
	})


	const [menu, setMenu] = useState(false);
	const [qtdeMensagens, setQtdeMensagens] = useState(<Spinner size="0.1rem" />);
	const [qtdCotacao, setQtdCotacao] = useState(<Spinner size="0.1rem" />);
	const [qtdServico, setQtdServico] = useState(<Spinner size="0.1rem" />);

	const [centro, setCentro] = useState([0, 0]);
	const [zoom, setZoom] = useState(3);
	const [endAtivos, setEndAtivos] = useState();

	const { dados, setDados, deslogar, websocket } = useContext(ContextoGeral)

	const isTest = useTestChecker(dados.dominio);

	const [btnConectar, setBtnConectar] = useState({
		...btnConectar,
		texto: <div className="my-2">
			<Spinner />
		</div>,
		textoPill: "",
		disabled: true,
	})

	const handlePuxarServico = () => {
		if (!dados.modulos?.puxaServico || (dados.modulos?.puxaServico && dados.modulos.puxaServico.ativo == "S"))
			if (btnConectar.ativo) navegar("/app/puxarServico");
			else {
				let msgErr = "Favor fique online para prosseguir!";
				if (window.AndroidInterface) window.AndroidInterface.showAndroidVersion(MensagemApp.deslogado);
				else alert(msgErr);
			}
	};

	const fecharDialog = () => setDialog(prev => ({ ...prev, show: false }))
	const mostrarTutorial = () => {
		fecharDialog()
		setTutorial(true)
	}

	const abrirConfig = () => {
		try {
			if (window.AndroidInterface) window.AndroidInterface.showSettingApp()
		} catch (error) { console.error(error) }
	}

	useEffect(() => {
		if (QTD.servicos > 0) if (!dados?.statusOnline) conectar(true);
	}, [QTD.servicos]);

	useEffect(() => {
		if (dados?.statusOnline) {
			//console.log('status 1 ',dados.statusOnline)	
			/**
			 * VERIFICAR TODAS PERMISSÕES
			 */
			let retorno = testaPermissoes();
			startService();
			//console.log('retorno 1 ',retorno);
		} else {
			//console.log('status 2 ',dados?.statusOnline)
		}
	}, [dados?.statusOnline]);

	async function handleProfLocation() {
		setZoom(20)
		try {
			if (window.AndroidInterface) {
				let localizacao = await JSON.parse(await window.AndroidInterface.getLocation());

				if (localizacao.latitude !== 0) {
					setCentro([localizacao.latitude, localizacao.longitude]);
					return true;
				} else return false
			} else return false
		} catch {
			console.error("não foi possível pegar a localização")
		}
	}

	function startService() {
		try {
			if (window.AndroidInterface)
				window.AndroidInterface.startBackgroundLocation(dados.idProf, dados.token, "enviarLocalizacao")
		} catch {
			console.error("Não possui função para iniciar o serviço de fundo")
		}
	}

	function stopService() {
		try {
			if (!!window.AndroidInterface)
				window.AndroidInterface.stopBackgroundLocation()
		} catch {
			console.error("Não possui função para fechar o serviço de fundo")
		}
	}

	async function testaPermissoes() {
		return await verificaPemissaoAndroid()

	}

	async function verificaPemissaoAndroid() {
		//console.log('aqui status =',dados.status);
		//console.log('aqui status online =',dados.statusOnline);
		if (window.AndroidInterface) {
			// PERMISSÃO DE NOTIFICAÇÃO
			if (!(await CheckPermission.checkNotificationAvailable())) {
				//console.log(' permissao notificacao ');
				window.AndroidInterface.showPermissaoNotification(MensagemApp.msgPermissaoNotificacao);
				return false;
			}

			// VERIFICA OTIMIZAÇÃO DE BATERIA. 
			try {
				if (!(await CheckPermission.checkBatteryOptimization())) {
					//console.log(' permissao bateria ');
					ProfissionalAPI.relatarAntifraude(dados, "OB")

					try {
						if (await window.AndroidInterface.versaoSdkAndroid() <= 25) {
							setDialog({
								show: dados.otimizacaoBateria != "NF",
								title: "ATENÇÃO",
								text: "Para você ficar online deve desativar a otimização de bateria. Acesse as configurações do aparelho para desativar a opção bateria/otimização nas configurações do seu android.",
								block: dados.otimizacaoBateria == "B",
								tutorial: true
							})
						} else {
							setDialog({
								show: dados.otimizacaoBateria != "NF",
								title: "ATENÇÃO",
								text: "Para você ficar online deve desativar a otimização de bateria. Acesse as configurações do aplicativo para desativar a opção bateria/otimização.",
								block: dados.otimizacaoBateria == "B",
								tutorial: false
							})
						}
					} catch (error) {
						console.error(error)
					}
				}
			} catch {
				console.error("Não foi possível checar otimização de bateria, não esta disponivel para esta versao do app")
			}

			// PERMISSÃO DE LOCALIZAÇÃO
			if (!(await CheckPermission.checkLocationAvailable())) {
				//console.log(' permissao localização ');
				window.AndroidInterface.showPermissaoLocalizacao(MensagemApp.msgPermissaoGPS)
				return false
			}

			// PERMISSÃO DE SERVIÇO DE FUNDO
			if (!(await CheckPermission.checkBackgroundLocationAvailable())) {
				//console.log(' permissao serviço de fundo ');
				let quantidadeOS = 0
				if (QTD.servicos > 0) quantidadeOS = QTD.servicos

				window.AndroidInterface.showPemissaoActivityBackgroundLocalizacao(
					dados.idProf,
					dados.token,
					"enviarLocalizacao",
					dados.statusOnline,
					quantidadeOS
				)

				return false
			}

			// LOCALIZAÇÃO ATIVA
			if (!CheckPermission.checkLocationEnabled()) {
				//console.log(' localização ativa ');
				window.AndroidInterface.showHabilitarLocalizacao(MensagemApp.msgHabilitarGPS)
				return false
			}

			// PERMISSÃO DE CAMERA
			if (!CheckPermission.checkCamera()) {
				//console.log(' permissao camera ');
				window.AndroidInterface.showPermissaoCamera(MensagemApp.msgPermissaoCamera)
				return false
			}

			// PERMISSÃO DE ARMAZENAMENTO
			if (!CheckPermission.checkStorage()) {
				//console.log(' permissao armazenamento ');
				window.AndroidInterface.showPermissaoArmazenamento(MensagemApp.msgPermissaoArmazenamento)
				return false
			}
		}

		return true
	}

	const conectar = async (isOnline = false) => {
		let statusPermissao = true
		setBtnConectar({
			...btnConectar,
			texto: (
				<div className="my-2">
					<Spinner />
				</div>
			),
			textoPill: "",
			disabled: true,
		})

		let status = dados.statusOnline ? "OF" : "OL"
		if (isOnline) status = 'OL'

		async function ativarLocal(resolve) {
			if (await verificaPemissaoAndroid()) {
				let local = await handleProfLocation();
				let rodadas = 0

				let intervalo = setInterval(async () => {
					rodadas += 1

					if (rodadas == 3 && window.AndroidInterface)
						window.AndroidInterface.showAndroidVersion("Recebendo sua localização...")

					if (local) {
						clearInterval(intervalo);
						resolve(true);
					} else {
						local = await handleProfLocation();

						if (rodadas == 10) {
							clearInterval(intervalo);
							statusPermissao = false;
							resolve(true);
						}
					}
				}, 1200)
			}
		}

		let jsonDados = dados

		let local = {
			latitude: 0,
			longitude: 0
		}

		await new Promise(ativarLocal)

		if (statusPermissao) {

			if (window.AndroidInterface && status) {
				local = JSON.parse(window.AndroidInterface.getLocation());
			}

			let retorno = await ProfissionalAPI.alterarStatus(status, dados, local?.latitude, local?.longitude)

			if (retorno?.resultado) {
				if (status == "OF") {
					setEndAtivos([])
					setDados(jsonDados)
					stopService()
				} else {
					buscarServicosExecucao(true)
					startService()
				}
			} else {
				if (window.AndroidInterface)
					window.AndroidInterface.showAndroidVersion(retorno.mensagem)

				if (retorno?.aux == 'usuarioInativo' || retorno?.aux == 'tokenInvalido') {
					await deslogar()
					status = 'OF'
				} else status = 'OL'

			}
		} else try {
			window.AndroidInterface.showAndroidVersion("Não foi possível obter sua localização")
		} catch {
			console.error("Você não está no Android")
			status = "OF"
		}

		if (status == 'OL') {
			setDados(prev => ({ ...prev, statusOnline: true }))
			startService()

			setBtnConectar({
				status: true,
				ativo: true,
				cor: 'var(--bs-danger)',
				variant: 'danger',
				texto: 'Desconectar',
				corPill: 'var(--bs-success)',
				textoPill: 'ONLINE'
			})

		} else {
			setDados(prev => ({ ...prev, statusOnline: false }))
			stopService()

			setBtnConectar({
				ativo: false,
				cor: 'var(--bs-success)',
				variant: 'success',
				texto: 'Conectar',
				corPill: 'var(--bs-danger)',
				textoPill: 'OFFLINE'
			})

		}

	}

	async function buscarServicosExecucao() {
		if (dados.statusOnline) {
			let local = { latitude: -19, longitude: -43 }

			if (window.AndroidInterface)
				local = JSON.parse(await window.AndroidInterface.getLocation())

			if (Object.keys(local).length > 0) {
				let retorno = await AppAPI.buscarServicosExecucao(dados, local)

				if (retorno?.resultado) {
					let enderecos = {}

					if (retorno?.dados?.enderecos.length > 0) {
						enderecos = retorno.dados.enderecos.map((endereco) => {
							let cor = "var(--bs-danger)"

							if (endereco["es.saida"] != null)
								cor = "var(--bs-success)"
							else if (endereco?.["es.chegada"] != null)
								cor = "var(--bs-warning)"

							if (endereco["corPontoMapa"])
								cor = endereco["corPontoMapa"]

							return {
								idServico: endereco["es.idServico"],
								id: endereco["e.id"],
								la: endereco["e.la"],
								lo: endereco["e.lo"],
								end: endereco["e.rua"],
								cor: cor,
							}
						})
					}

					setEndAtivos(enderecos);
				} else {
					if (retorno?.aux == "usuarioInativo" || retorno?.aux == "tokenInvalido")
						deslogar()
				}
			}
		}
	}

	async function buscarSaldo() {
		if (!dados.hasOwnProperty("saldo")) {
			let retornoSaldo = await ProfissionalAPI.buscarSaldo(dados)

			let jsonDados = dados

			if (retornoSaldo?.resultado)
				setDados((prev) => ({ ...prev, saldo: retornoSaldo.dados.saldo }))
			else {
				if (retornoSaldo?.aux == "usuarioInativo" || retornoSaldo?.aux == "tokenInvalido")
					deslogar()

				jsonDados.saldo = 0.0
				setDados(jsonDados)
			}
		}
	}

	async function buscarQtdMensagemNaoLida() {
		let retornoMensagem = await MensagemAPI.qtdMensagensNaoLida(dados);
		if (retornoMensagem?.resultado)
			setQtdeMensagens(retornoMensagem.dados.qtdeMensagens);
		else {
			if (retornoMensagem?.aux == "usuarioInativo" || retornoMensagem?.aux == "tokenInvalido")
				deslogar()

			setQtdeMensagens(0);
		}
	}

	async function buscarQtdCotacaoServico() {
		let retorno = await ServicoAPI.qtdServico(dados)

		if (retorno?.resultado) {
			setQTD(p => ({
				...p,
				cotacoes: retorno.dados.qtdeCotacoes,
				servicos: retorno.dados.qtdeServicos,
				puxar: retorno.dados.qtdPuxarServico
			}))

			if (retorno.dados.statusProf == "OL") {
				handleProfLocation()
				setDados(prev => ({ ...prev, statusOnline: true }))

				setBtnConectar({
					status: true,
					ativo: true,
					cor: 'var(--bs-danger)',
					variant: 'danger',
					texto: 'Desconectar',
					corPill: 'var(--bs-success)',
					textoPill: 'ONLINE'
				})
			} else {
				setDados(prev => ({ ...prev, statusOnline: false }))

				setBtnConectar({
					ativo: false,
					cor: 'var(--bs-success)',
					variant: 'success',
					texto: 'Conectar',
					corPill: 'var(--bs-danger)',
					textoPill: 'OFFLINE'
				})
			}
		} else {
			if (retorno?.aux == "usuarioInativo" || retorno?.aux == "tokenInvalido")
				deslogar()

			setQTD(p => ({
				...p,
				cotacoes: 0,
				servicos: 0,
				puxar: 0
			}))
		}
	}

	useEffect(() => {
		buscarSaldo()
		buscarQtdMensagemNaoLida()
		buscarQtdCotacaoServico()
		buscarServicosExecucao()
		// statusOnlineProf()
	}, [])

	useLayoutEffect(() => {
		if (refPerfil && refLocal && refMenu && refServices && refCotation && refPull && refMsg) {
			setTimeout(() => {
				if (isTest && dados.tutoriais.mapa) setOpen(true)
			}, 1500)

			setPopAnchor(refPerfil)
			setLocalAnchor(refLocal)
			setMenuAnchor(refMenu)
			setServicesAnchor(refServices)
			setCotationAnchor(refCotation)
			setPullAnchor(refPull)
			setMsgAnchor(refMsg)
		}
	}, [])

	useEffect(() => {
		if (open)
			setTimeout(async () => {
				setOpen(false)

				let json = dados
				json.tutoriais.mapa = false
				setDados(json)
			}, 2000)
	}, [open])

	const { dadosNotificacoes } = useMessageCount(true, location);
	const [mostrarNotificacoes, setMostrarNotificacoes] = useState('none');
	const [notifCentral, setNotifCentral] = useState(0);

	// Calcula a quantidade de mensagens da sala 'Central' uma vez
	useEffect(() => {

		const chaveSala = `P${dados.idProf}`

		if (dadosNotificacoes?.salas) {

			setNotifCentral(dadosNotificacoes?.salas[chaveSala]);
		}

	}, [dadosNotificacoes]);

	function abrirChat() {
		if (
			dadosNotificacoes.salas &&
			Object.entries(dadosNotificacoes.salas).some(([key, value]) => value > 0 && !key.startsWith("P"))
		) {
			if (mostrarNotificacoes == 'none') {
				setMostrarNotificacoes('block')
			} else {
				setMostrarNotificacoes('none')
			}
		} else {

			navegar('/app/chat', {
				state: {
					conversa: 'central',
					notificacao: dadosNotificacoes
				}
			})
		}
	}

	function selecionarSala(nomeSala, id, sala = '') {

		if (nomeSala == 'central') {
			navegar('/app/chat', {
				state: {
					conversa: 'central',
					notificacao: dadosNotificacoes
				}
			})
		} else {

			navegar(`/app/chat`, {
				state: {
					conversa: 'cliente',
					idServico: dadosNotificacoes.dadosServico[sala].idServico,
					idSolicitante: dadosNotificacoes.dadosServico[sala].idSolicitante,
					idCentroCusto: dadosNotificacoes.dadosServico[sala].idCentroCusto,
					status: dadosNotificacoes.dadosServico[sala].status,
					notificacao: dadosNotificacoes
				}
			})
		}
	}

	return <motion.div
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
	>
		<DivMapa zoom={zoom} center={centro} setCenter={setCentro} enderecos={endAtivos}>
			<div className="v80">
				<BlocoFlutuante top="-100vh" left="0pt" ajustado>
					<div ref={refPerfil}>
						<BtnRedondo
							onClick={() => {
								navegar("/app/perfil");
							}}
							pura
						>
							<Badge badgeContent={dados.foto == "" ? "!" : null} color="error">
								<Avatar
									src={dados.foto}
									sx={{
										margin: 0,
										width: 48,
										height: 48,
									}}
								/>
							</Badge>
						</BtnRedondo>
					</div>
					<Popover
						id="teste"
						open={open}
						anchorEl={popAnchor?.current}
						anchorOrigin={{
							vertical: "center",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "center",
							horizontal: "left",
						}}
					>
						<Balloon>Perfil</Balloon>
					</Popover>

					<AtivoPill texto={btnConectar.textoPill} cor={btnConectar.corPill} />
				</BlocoFlutuante>

				<BlocoFlutuante bottom="70pt" left="0pt">
					<div ref={refLocal}>
						<BtnRedondo
							collapse={btnConectar.ativo}
							onClick={handleProfLocation}
						>
							<MdMyLocation />
						</BtnRedondo>
					</div>
					{btnConectar.ativo && (
						<Popover
							id="location"
							open={open}
							anchorEl={localAnchor?.current}
							onClose={() => {
								setOpen(false);
								setPopAnchor(null);
							}}
							anchorOrigin={{
								vertical: "center",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "center",
								horizontal: "left",
							}}
						>
							<Balloon>Ver sua localização</Balloon>
						</Popover>
					)}
				</BlocoFlutuante>

				<BlocoFlutuante bottom="70pt" right="0pt">
					<motion.div>
						<Popover
							id="services"
							open={open}
							anchorEl={servicesAnchor?.current}
							onClose={() => {
								setOpen(false);
								setPopAnchor(null);
							}}
							anchorOrigin={{
								vertical: "center",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "center",
								horizontal: "right",
							}}
						>
							<Balloon>Serviços</Balloon>
						</Popover>

						{websocket && <div
							variant='contained'
							onClick={abrirChat}
						>
							<BtnRedondo badge={dadosNotificacoes?.total}>
								<MdChat style={{ color: 'black' }} />
							</BtnRedondo>
						</div>}

						<div ref={refServices}
							onClick={() => {
								navegar("/app/servicos");
							}}
						>
							<BtnRedondo badge={QTD.servicos}>
								<MdPlaylistAddCheck />
							</BtnRedondo>
						</div>

						<div ref={refCotation}
							onClick={() => {
								navegar("/app/cotacoes");
							}}
						>
							<BtnRedondo badge={QTD.cotacoes}>
								<MdAddTask />
							</BtnRedondo>
						</div>
						<Popover
							id="cotation"
							open={open}
							anchorEl={cotationAnchor?.current}
							onClose={() => {
								setOpen(false);
								setPopAnchor(null);
							}}
							anchorOrigin={{
								vertical: "center",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "center",
								horizontal: "right",
							}}
						>
							<Balloon>Cotações</Balloon>
						</Popover>
						<div ref={refPull} onClick={handlePuxarServico}>
							<BtnRedondo
								badge={QTD.puxar}
								collapse={
									btnConectar.ativo &&
									(!dados.modulos?.puxaServico ||
										(dados.modulos?.puxaServico && dados.modulos.puxaServico.ativo == "S"))
								}
							>
								<MdAddLocationAlt />
							</BtnRedondo>
						</div>

						{btnConectar.ativo &&
							(!dados.modulos?.puxaServico ||
								(dados.modulos?.puxaServico && dados.modulos.puxaServico.ativo == "S")) && (
								<Popover
									id="pull"
									open={open}
									anchorEl={pullAnchor?.current}
									onClose={() => {
										setOpen(false);
										setPopAnchor(null);
									}}
									anchorOrigin={{
										vertical: "center",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "center",
										horizontal: "right",
									}}
								>
									<Balloon>Puxar serviços</Balloon>
								</Popover>
							)}

						<div
							style={{
								position: 'fixed',
								bottom: '35vh', // distância do rodapé
								right: '6px', // distância da direita
								zIndex: 1000,  // para garantir que o botão fique sobre outros elementos
							}}
						>
							{dadosNotificacoes?.total > 0 && (
								<span
									style={{
										marginLeft: '-7px',  // Espaço entre o texto e o círculo
										backgroundColor: 'rgb(255 0 0)',
										borderRadius: '50%',
										width: '28px',
										height: '28px',
										display: 'inline-flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: 'white',
										fontSize: '14px',
										fontWeight: 'bold',
										bottom: '38px',
										left: '47px',
										position: 'absolute',
									}}
								>
									{dadosNotificacoes?.total}
								</span>
							)}
						</div>

					</motion.div>
				</BlocoFlutuante>
				<BlocoFlutuante bottom="0pt">
					<div className="rodapeMapa">
						<div className="position-relative">
							<div ref={refMsg}>
								{status == 0
									? <Spinner />
									: <>
										{dados?.chatConfig?.status == "S"
											? <>
												<MdNotifications onClick={() => {
													navegar("/app/mensagens");
												}}
												/>
												{qtdeMensagens > 0 ? <Badge top="0.3rem" right="-0.5rem" valor={qtdeMensagens} /> : ""}
											</>
											: <>
												<MdChat onClick={() => {
													navegar("/app/mensagens");
												}} />
												{qtdeMensagens > 0 ? <Badge top="0.3rem" right="-0.5rem" valor={qtdeMensagens} /> : ""}
											</>}
									</>}
							</div>

							<Popover
								id="pull"
								open={open}
								anchorEl={msgAnchor?.current}
								onClose={() => {
									setOpen(false);
									setPopAnchor(null);
								}}
								anchorOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
							>
								<Balloon>Notificações</Balloon>
							</Popover>
						</div>
						<div id="blocoBtnConectar" onClick={() => conectar()}>
							<BtnConectar {...btnConectar} />
						</div>
						<div>
							<div ref={refMenu}>
								<MdMenu
									onClick={() => {
										setMenu(true);
									}}
								/>
							</div>
							<Popover
								id="pull"
								open={open}
								anchorEl={menuAnchor?.current}
								onClose={() => {
									setOpen(false);
									setPopAnchor(null);
								}}
								anchorOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "bottoabrirConfigm",
									horizontal: "center",
								}}
							>
								<Balloon>Menu</Balloon>
							</Popover>
						</div>
					</div>
				</BlocoFlutuante>
			</div>
			<MenuLateral show={menu} setShow={setMenu} ativo="0" />
		</DivMapa>
		<Dialog open={dialog.show}>
			<DialogTitle className="text-center">{dialog.title}</DialogTitle>
			<DialogContentText className="px-3">{dialog.text}</DialogContentText>
			<DialogActions>
				<Button
					onClick={
						dialog.tutorial
							? mostrarTutorial
							: abrirConfig
					}
				>
					{dialog.tutorial
						? "Tutorial"
						: "Acessar"}
				</Button>
				{!dialog.block && <Button onClick={fecharDialog}>
					OK
				</Button>}
			</DialogActions>
		</Dialog>
		<Backdrop
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: "blur(5px)" }}
			open={tutorial}
			onClick={() => {
				setDialog(prev => ({ ...prev, show: true }))
				setTutorial(false)
			}}
		>
			<div className="text-white p-3 small">
				<div className="d-flex justify-content-end mb-3">
					<Close style={{ fontSize: '2.5rem' }} />
				</div>
				<p>
					Para desativar a otimização de bateria no seu celular acesse as configurações do Android.
				</p>
				<p>
					Em seguida procure pela opção <b>bateria</b> ou similar.
				</p>
				<p>
					Procure pela opção <b>Otimização de bateria</b>, que geralmente é encontrada no canto superior direito.
				</p>
				<p>
					Uma lista de aplicativos deverá aparecer, verifique se a lista está marcada com a opção <b>Todos os apps</b>.
				</p>
				<p>
					Procure por este aplicativo e o selecione, escolha a opção <b>Não otimizar</b> e selecione <b>concluído</b>.
				</p>
				<p>
					Seguindo estes passos você poderá retornar para esta tela novamente.
				</p>
				<div>
					{/* <Button variant="contained">
						fechar
					</Button> */}
				</div>
			</div>
		</Backdrop>
	</motion.div >
}
